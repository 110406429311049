<template>
  <v-container>
    <v-row>
    <v-col>
    <h2>User List</h2>
    <v-btn @click="openModal()">Add New User</v-btn>
    </v-col>
    <v-col align="right">
      <download-csv
        class="mt-5"
        :data="fullData">
        <v-btn>Download Data</v-btn>
      </download-csv>
    </v-col>
    </v-row>
    <template>
      <v-data-table dense 
        :headers="headers"
        :items="dataTable"
        :items-per-page="5"
        class="elevation-1 mt-5"
      ></v-data-table>
</template>

<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5">
          Add New User
        </v-card-title>

        <v-container>
          <v-text-field
            label="Email"
            placeholder="john.doe@email.com"
            v-model="email"
          ></v-text-field>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false, createUser()"
          >
            Add User
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

  </v-container>
</template>

<script>
import axios from 'axios'; 
  export default {
  created() {
     this.$auth.getTokenSilently().then((authToken) => {
        this.token = authToken;
        this.getUser();
     });
  
  },
  methods: {
    openModal() {
      this.dialog = true;
    },
    getUser() {
            axios
            .get(process.env.VUE_APP_API + 'participants', { headers: {
              Authorization: `Bearer ${this.token}`
            }
          })
          .then(response => {
            console.log(response.data);
            this.dataTable = response.data.map(d => ({email: d.email, test_completed: d.results.length >= 10}))
            this.fullData = response.data.map(d => ({
              id: d.participant_id,
              gender: d.demographics?.gender,
              age: d.demographics?.age,
              industry: d.demographics?.industry,
              number_subordinates: d.demographics?.number_subordinates,
              position: d.demographics?.position,
              res_SENS_0_correct: d.results[0]?.number_correct,
              res_SENS_0_incorrect: d.results[0]?.number_incorrect,
              res_SENS_1_correct: d.results[1]?.number_correct,
              res_SENS_1_incorrect: d.results[1]?.number_incorrect,
              res_DED_0_correct: d.results[2]?.number_correct,
              res_DED_0_incorrect: d.results[2]?.number_incorrect,
              res_DED_1_correct: d.results[3]?.number_correct,
              res_DED_1_incorrect: d.results[3]?.number_incorrect,
              res_TYR_0_correct: d.results[4]?.number_correct,
              res_TYR_0_incorrect: d.results[4]?.number_incorrect,
              res_TYR_1_correct: d.results[5]?.number_correct,
              res_TYR_1_incorrect: d.results[5]?.number_incorrect,
              res_STR_CHR_0_correct: d.results[6]?.number_correct,
              res_STR_CHR_0_incorrect: d.results[6]?.number_incorrect,
              res_STR_CHR_1_correct: d.results[7]?.number_correct,
              res_STR_CHR_1_incorrect: d.results[7]?.number_incorrect,
              res_CRT_INT_0_correct: d.results[8]?.number_correct,
              res_CRT_INT_0_incorrect: d.results[8]?.number_incorrect,
              res_CRT_INT_1_correct: d.results[9]?.number_correct,
              res_CRT_INT_1_incorrect: d.results[9]?.number_incorrect,
            }))
            })
          .catch(error => console.log(error));
    },
     createUser() {
          const body = { email: this.email };
          axios
            .post(process.env.VUE_APP_API + 'create-user', body, { headers: {
                Authorization: `Bearer ${this.token}`
              }
            })
            .then((res) => console.log(res)) //todo, do something
    }
  },
    data () {
      return {
        dialog: false,
        headers: [
          { text: 'Email', value: 'email'}, {text: 'Test Completed?', value: 'test_completed'},
        ],
        fullData: [],
        dataTable: [
        ],
        email: '',
        token: null,
      }
    }
  }
</script>