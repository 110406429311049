import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import JsonCSV from 'vue-json-csv'
import Signin from './components/Signin';
import Users from './components/Users'

// Import the Auth0 configuration
import { domain, clientId, audience} from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

Vue.component('download-csv', JsonCSV)

import VueRouter from 'vue-router'
import { authGuard } from './auth/authGuard';

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Signin
    },
    {
      path: '/users',
      name: 'Users',
      component: Users,
      beforeEnter: authGuard
    }
  ]
})

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: () => {
    router.push({name: 'Users'});
  }
});


Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
